import React, { useEffect, useRef, useState, useMemo } from "react";
import { createAssetIdToken } from "../../scripts/utils";
import * as classes from "./RadiantPlayer.module.css";
import * as setting from "../../configs/config_settings.json";

import SkeletonVideoPlayer from "../SkeletonLoading/SkeletonVideoPlayer";

export default function LivePlayer1(props) {
	const rmpPlayer = useRef();
	const rmpPlayer1 = useRef();

	const settings = useRef();

	const [channelServiceId, setChannelServiceId] = useState(null);
	const [radiantMediaPlayerObject, setRadiantMediaPlayerObject] = useState(null);

	useMemo(() => {
		setChannelServiceId(props.channelServiceId);
	}, [props.channelServiceId]);

	useEffect(() => {
		return () => {
			console.log(window?.LIVE?.idleEventInterval, "window?.LIVE?.idleEventInterval");
			clearInterval(window?.LIVE?.idleEventInterval);
		};
	}, [window?.LIVE?.idleEventInterval]);

	useEffect(() => {
		let liveScript, advertjs;

		if (channelServiceId && !settings.current && !radiantMediaPlayerObject) {
			// no need vod.js for live event/video
			// need to load both live.js and event script for events which are  live
			console.log(1111);
			const createScript = () => {
				liveScript = document.createElement("script");
				advertjs = document.createElement("script");

				liveScript.id = "livejs";

				liveScript.src = "https://my.icareus.com//lib/js/v3/players/liveUpdated.js";
				// liveScript.src =
				//   "https://my.icareus.com//suite/testing/liveUpdated.js";

				liveScript.async = true;

				advertjs.src = "https://my.icareus.com//lib/js/v4/players/adverts.js";
				advertjs.async = true;

				let oldLiveScript = document.getElementById("livejs");

				!oldLiveScript && document.body.appendChild(liveScript);
				document.body.appendChild(advertjs);
			};
			createScript();

			window._icareus = {};

			window._icareus.companyId = props.companyId;

			window._icareus.groupId = props.groupId;

			window._icareus.organizationId = props.organizationId;
			// itemID and serviceId is same and it must be passed
			window._icareus.itemId = channelServiceId;
			window._icareus.serviceId = channelServiceId;
			window._icareus.host =
				setting.API_Server_Number === 1 ? "https://my.icareus.com" : "https://suiterc.icareus.com";

			window._icareus.playerId = "rmpLivePlayer";
			window._icareus.playerType = "radiant";
			window._icareus.playerSetup = "startOnDemandPlayer";
			window._icareus.playerAutoStart = true;
			window._icareus.userId = props.userId;
			window._icareus.licenseFileUrl = "//cache.mana2.my/suite/radiantplayerlicenses.json";
			window._icareus.streamRootKeysUrl = "//cache.mana2.my/suite/streamrootkeys.json";
			window._icareus.useAdvertsLibrary = true;

			window._icareus.token = createAssetIdToken(
				props.organizationId,
				channelServiceId,
				false,
				props.token
			);

			// if vod, live or advert script is   in initialized from react apps
			//   window._icareus.initializedByReactApps = true;

			window.startOnDemandPlayer = function () {
				settings.current = {
					licenseKey: window.location.hostname.includes("icareus.com")
						? setting.radiantMediaLicenseIcareus
						: setting.radiantMediaLicense,
					src: window._icareus.sources,

					autoHeightMode: true,
					autoHeightModeRatio: 1.7777777778,
					autoplay: true,
					skin: props.skin ? props.skin : "s1",

					skinBackgroundColor: props.skinBackgroundColor
						? props.skinBackgroundColor
						: "rgba(33, 33, 33, 0.85)",
					skinButtonColor: "rgba(255, 255, 255, 1)",
					skinAccentColor: "rgba(130, 177, 255, 1)",

					speed: props.speed ? true : false,
					automaticFullscreenOnLandscape: true,
					adTagUrl: window._icareus.videoVastURL,
					ads: true,
					hlsJSStopDownloadWhilePaused: true,
					contentMetadata: {
						title: props.title ? props.title : null,
						description: props.description ? props.description : null,
					},
					muted: true,
					allowLocalStorage: false,

					asyncElementID: rmpPlayer?.current?.id,
				};
				console.log("settings", settings);

				settings && window._icareus?.playerObject?.init({ ...settings.current });

				setRadiantMediaPlayerObject(window._icareus.playerObject);
			};
			console.log("window", window);
		}
		return () => {
			// pausing the rmp
			radiantMediaPlayerObject?.pause();
			// muting the rmp
			radiantMediaPlayerObject?.setMute(true);
			// destroying the rmp
			radiantMediaPlayerObject?.destroy();
			setRadiantMediaPlayerObject(null);

			window._icareus?.playerObject?.destroy();
			liveScript && document?.body?.removeChild(liveScript);
			advertjs && document?.body?.removeChild(advertjs);
			settings.current = null;

			//   window._icareus = null;
			window._icareus = null;
			console.log(12345);
			// props.setLoading(true);
		};
	}, [channelServiceId, props.loading]);
	console.log("radiantMediaPlayerObject", radiantMediaPlayerObject);
	// eslint-disable-next-line react-hooks/exhaustive-deps

	return props.loading ? (
		<SkeletonVideoPlayer />
	) : (
		<div
			style={{
				position: "relative",
				border: "none !important",
			}}
			className={classes.RadiantPlayer}
		>
			<div
				style={{
					position: "absolute",
					inset: 0,
					zIndex: 1,
					border: "none !important",
				}}
				className={classes.RadiantPlayer}
			>
				<SkeletonVideoPlayer />
			</div>

			<div
				className={classes.RadiantPlayer}
				id="rmplive2"
				ref={rmpPlayer1}
				style={{
					position: "absolute",
					inset: 0,
					zIndex: 2,
					border: "none",
				}}
			>
				{<div ref={rmpPlayer} id="rmpLivePlayer"></div>}
			</div>
		</div>
	);
}
